<template>
  <PopoverRoot v-model:open="open">
    <PopoverTrigger v-bind="$attrs">
      <slot name="reference"></slot>
    </PopoverTrigger>
    <PopoverPortal>
      <div class="relative z-10">
        <PopoverContent
          as-child
          :align="align"
          :align-offset="alignOffset"
          :side="side"
          :side-offset="sideOffset"
          class="PopoverContent rounded-2xl bg-white p-5 shadow-[0_1px_30px_0px_rgba(113,113,113,.35)]"
        >
          <div>
            <slot></slot>

            <PopoverClose
              v-if="showCloseIcon"
              class="absolute right-4 top-4 cursor-pointer rounded-full p-2 text-0 hover:bg-[#F0F0F0]"
              aria-label="Close"
              @click="open = false"
            >
              <div class="i-local-close text-lg"></div>
            </PopoverClose>
            <PopoverArrow class="fill-white" />
          </div>
        </PopoverContent>
      </div>
    </PopoverPortal>
  </PopoverRoot>
</template>

<script setup lang="ts">
import { PopoverArrow, PopoverClose, PopoverContent, PopoverPortal, PopoverRoot, PopoverTrigger } from 'radix-vue'
import type { PropType } from 'vue'

defineProps({
  showCloseIcon: {
    type: Boolean,
    default: true,
  },
  side: {
    type: String as PropType<'bottom' | 'top' | 'right' | 'left'>,
    default: 'bottom',
  },
  sideOffset: {
    type: Number,
    default: 5,
  },
  align: {
    type: String as PropType<'start' | 'center' | 'end'>,
    default: 'center',
  },
  alignOffset: {
    type: Number,
    default: 5,
  },
})

const open = defineModel('open', {
  type: Boolean,
  default: false,
})
</script>

<style scoped>
.PopoverContent {
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.PopoverContent[data-side="top"] {
  animation-name: slideUp;
}
.PopoverContent[data-side="bottom"] {
  animation-name: slideDown;
}
</style>
